<template>
  <b-container>
    <b-row>
      <b-col cols="12" class="mt-4">
        <b-card class="mb-2" bg-variant="dark" text-variant="white">
          <h1>Whitelist</h1>
          <b-row align-h="between">
            <b-col sm="12" lg="8">
              <b-card-text
                >This list of whitelist addresses is not updated in realtime,
                you may need to refresh the page.</b-card-text
              >
            </b-col>
            <b-col sm="12" lg="4">
              <span style="color: orange"
                >Collection ID: {{ collectionId }}</span
              >
            </b-col>
          </b-row>
          <b-row class="mt-4 mb-2" align-h="between">
            <b-col sm="12" lg="5">
              <b-form-input
                v-model="filter"
                placeholder="Search"
              ></b-form-input>
            </b-col>
            <b-col sm="12" lg="3">
              <b-button @click="download">Download</b-button>
            </b-col>
          </b-row>
          <template v-if="!loading">
            <b-table
              v-if="items.length"
              dark
              striped
              :fields="tableFields"
              :filter="filter"
              :filter-included-fields="['id']"
              :items="items"
              responsive
            >
            </b-table>
            <b-alert v-else show> No results found </b-alert>
          </template>
          <div v-if="!loading" class="text-center">
            <b-button
              v-b-modal.modal-stakekey
              variant="success"
              :disabled="btnloading"
              >Add stake key</b-button
            >
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      id="modal-stakekey"
      ref="modal"
      title="Submit Stake key"
      @show="resetModal"
      @hidden="resetModal"
      hide-footer
    >
      <form ref="form" @submit.prevent="">
        <b-form-group class="mb-3" label="Stake Key" label-for="stakekey-input">
          <b-form-input
            id="stakekey-input"
            v-model="newStakeKey.id"
            required
          ></b-form-input>
          <div v-if="errors">
            <div v-for="(error, index) of errorObj.id" :key="index">
              <span class="text-danger ps-1">{{ error }}</span>
            </div>
          </div>
        </b-form-group>
        <b-form-group
          class="mb-3"
          label="Mint Limit"
          label-for="keylimit-input"
        >
          <b-form-input
            id="keylimit-input"
            type="number"
            v-model.number="newStakeKey.keyLimit"
            required
          ></b-form-input>
          <div v-if="errors">
            <div v-for="(error, index) of errorObj.keyLimit" :key="index">
              <span class="text-danger ps-1">{{ error }}</span>
            </div>
          </div>
        </b-form-group>
        <b-form-group
          class="mb-3"
          label="Minted Count"
          label-for="mintcount-input"
        >
          <b-form-input
            id="mintcount-input"
            type="number"
            v-model.number="newStakeKey.mintedCount"
            disabled
            required
          ></b-form-input>
          <div v-if="errors">
            <div v-for="(error, index) of errorObj.mintedCount" :key="index">
              <span class="text-danger ps-1">{{ error }}</span>
            </div>
          </div>
        </b-form-group>
        <div class="text-center mb-3">
          <b-button
            variant="success"
            @click="sendNewstakeKey()"
            :disabled="btnloading"
            >Add</b-button
          >
        </div>
        <b-alert v-if="duplicateMsg" show variant="danger">Stake Key already added</b-alert>
      </form>
    </b-modal>
  </b-container>
</template>

<script>
import { db } from "@/firebase";
import validate from "validate.js";
import constraints from "../../../src/stakekeyFormConstraints";
import { collection, query, getDocs, doc, setDoc } from "firebase/firestore";
import Papa from "papaparse";
import { bech32 } from "bech32";

export default {
  name: "ConsoleWhitelist",
  data() {
    return {
      newStakeKey: {
        id: "",
        keyLimit: null,
        mintedCount: 0,
      },
      duplicateMsg: false,
      btnloading: false,
      errors: false,
      errorObj: null,
      collectionId: this.$route.params.id,
      items: [],
      loading: true,
      filter: "",
      id: "",
      tableFields: ["id", "keyLimit", "mintedCount"],
    };
  },
  async created() {
    const stakeKeyCollection = collection(
      db,
      "collections",
      this.collectionId,
      "stake_keys"
    );
    const theQuery = query(stakeKeyCollection);
    const stakeKeys = await getDocs(theQuery);
    this.items = stakeKeys.docs.map((x) => {
      let data = x.data();
      data.id = x.id;
      return data;
    });
    this.loading = false;
  },
  methods: {
    download() {
      // credit: https://www.bitdegree.org/learn/javascript-download
      let filename = "whitelist.csv";
      let text = Papa.unparse(this.items);

      let element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/csv;charset=utf-8," + encodeURIComponent(text)
      );
      element.setAttribute("download", filename);

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();
      document.body.removeChild(element);
    },
    async sendNewstakeKey() {
      this.btnloading = true;
      this.newStakeKey.id = this.getStakeKeyFromAddress(this.newStakeKey.id);
      const formVal = validate(this.newStakeKey, constraints);

      if (formVal) {
        this.errors = true;
        this.errorObj = formVal;
      } else {
        this.errors = false;
        // Check stake key to avoid duplicate of it
        const compareObjs = this.items.map((i) => i.id === this.newStakeKey.id);
        if (compareObjs.includes(true)) {
          this.duplicateMsg = true;
        } else {
          this.duplicateMsg = false;
          // Push and show new item in frontend
          this.items.push(this.newStakeKey);
          // Delete unnecessary field
          delete this.newStakeKey.stakeKey;
          // Destructuring obj
          const { id, ...data } = this.newStakeKey;
          // Send data
          const stakeKeyRef = doc(
            db,
            `collections/${this.collectionId}/stake_keys`,
            id
          );
          try {
            await setDoc(stakeKeyRef, data);
            this.$bvModal.hide("modal-stakekey");
          } catch (error) {
            console.log(error);
          }
        }
      }
      this.btnloading = false;
    },
    resetModal() {
      this.duplicateMsg = false;
      this.newStakeKey = {
        stakeKey: "",
        keyLimit: null,
        mintedCount: 0,
      };
    },
    getStakeKeyFromAddress(address) {
      // Decode bech32 shelley address and convert to hex
      // Setting higher limit as Cardano addresses can be longer than 90 character default limit
      const addrDecoded = bech32.decode(address, 200);
      const addrDecodedWords = bech32.fromWords(addrDecoded.words);
      const addrDecodedHEx = Buffer.from(addrDecodedWords, "utf-8").toString(
        "hex"
      );
      // Get last 56 characters which is the stakeKey
      // Must add 'e1' for mainnet and 'e0' for testnet to string
      const stakeAddrHex =
        "e1" + addrDecodedHEx.slice(addrDecodedHEx.length - 56);

      // re-encode stake part of address back to bech32
      const stakeAddress = bech32.encode(
        "stake",
        bech32.toWords(Uint8Array.from(Buffer.from(stakeAddrHex, "hex"))),
        200
      );

      return stakeAddress;
    },
  },
};
</script>

<style>
.modal-header {
  border-bottom: 0;
}

.modal-header .close {
  background: transparent;
  color: rgb(0, 0, 0);
  border: none;
  font-size: 20px;
}
</style>
