const constraints = {
    id: {
        presence: {allowEmpty: false, message: "^Can't be blank"},
        format: {
            pattern: /^(stake)+[a-z-0-9]*$/,
            message: "^Invalid format"
          }
    },
    keyLimit: {
        presence: {allowEmpty: false},
        numericality: { strict: true, noStrings: true }
    },
    mintedCount: {
        presence: {allowEmpty: false},
        numericality: { strict: true, noStrings: true }
    }
}

export default constraints;